import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Imgbase from 'gatsby-image';
import { color, breakpoint } from '@src/settings';

export const Cont = styled.div`
	display:flex;
	justify-content:space-between;
	align-items: center;
	background-color: #FFF;
	margin-top: 0.4rem;
	padding: 3rem 5rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
		padding: 2.5rem;
	`}
`;

export const Img = styled(Imgbase)`
	align-self: center;
	flex: 20rem 0 0;	
	margin-right: 2rem;
	${ props => props.withBorder && `
		border: 1px solid #DDD;
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-right: 0;
		margin-bottom: 1rem;
	`}
`;

export const Title = styled.h4`
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 1.8rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-bottom: 0.5rem;
	`}
`;

export const Cap = styled.p`
	font-size: 1.4rem;
	line-height: 1.8;
	color: ${color.txtLight};
`

export const Data = styled.dl`
	${ Cap } + &{
		margin-top: 1.5rem;
	}
	display: flex;
	padding: 1.4rem 0;
	& > dt,
	& > dd{
		text-align: left;
		font-size: 1.3rem;
		vertical-align: top;
	}

	& > dt{
		flex: 12rem 0 0;
		margin-right: 1rem;
		font-weight: bold;
		color: ${color.txtLight};
	}

	& > dd{
		flex: auto 1 1;
		color: ${color.txtLight};
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
		padding: 0.5rem 0;
		${ Cap } + &{
			margin-top: 0.5rem;
		}
	`}
`

export const Content = styled.div`
	flex: auto 1 1;
	padding:1rem;
	${ Data } + ${ Data }{
		border-top: 1px solid #EDEDED;
	}
`
