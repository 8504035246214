import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery} from 'gatsby';
import Image from 'gatsby-image';

import { mixin, color } from '@src/settings';

import Hero from '@components/en/common/hero';
import PageIntro from '@components/common/page-intro';
import ContactIntro from '@components/en/common/contact-intro';

import Quality_Hero__mds from '@img/svg/quality/hero__mds.svg';

import Column from '@components/quality/column';
import { PageCont, Title, Container } from '@components/quality/styles';

const QualityPage = ({ data }) => (
	<PageCont baseBgColor={ color.paleGray }>
		<Hero
			img={ data["Quality_hero_img"] }
			title={<Quality_Hero__mds />}
		/>
		<PageIntro
			title="Our L.S.F. Missions"
			subtitle="To preserve the harmony between people, environment, and society, we aim to create win-win relationship with all of our stakeholders by fulfilling our corporate missions and through CSR activities."
		 />
		<Container>

			<Title>ISO ACHIEVEMENTS</Title>

			<Column className="Column"
				img={ data["Iso14001"] }
				title="Certificate ISO 14001"
				cap="In January 2013, we acquired ISO14001 certification, which is an international standard for quality management."
				data={[
					{ key:"Compliant standard", val:"ISO14001:2004"},
					{ key:"Registration number", val:"413127E"},
					{ key:"Registered business", val:"LSF Technology Kajang Factory"}
				]}
				isIso
			/>

			<Column className="Column"
				img={ data["Iso14001_2015"] }
				title="Certificate ISO 14001"
				cap="In January 2013, we acquired ISO14001 certification, which is an international standard for quality management."
				data={[
					{ key:"Compliant standard", val:"ISO14001:2020"},
					{ key:"Registration number", val:"647739-M"},
					{ key:"Registered business", val:"LSF Technology Kajang Factory"}
				]}
				isIso
			/>

			<Column className="Column"
				img={ data["Iso9001"] }
				title="Certificate ISO 9001"
				cap="In January 2004, we acquired ISO9001 certification, an international standard for environmental management."
				data={[
					{ key:"Compliant standard", val:"ISO9001:2015"},
					{ key:"Registration number", val:"16146-A"},
					{ key:"Registered business", val:"LSF Technology Kajang Factory"}
				]}
				isIso
			/>

			<Column className="Column"
				img={ data["Iatf16146"] }
				title="Certificate IATF 16949"
				cap="In April 2017, we acquired IATF 16949 certification, which is an international standard for automobile industry quality management."
				data={[
					{ key:"Compliant standard", val:"IATF 16949:2009"},
					{ key:"Registration number", val:"16146-T"},
					{ key:"Registered business", val:"LSF Technology Kajang Factory"}
				]}
				isIso
			/>
		</Container>

		<Container>
			<Title>Awards</Title>

			<Column className="Column"
				img={ data["Daihatsu_regional_contribution_award"] }
				title="Daihatsu Regional Contribution Award(2014)"
			/>

			<Column className="Column"
				img={ data["Perodua_best_overall"] }
				title="Perodua Best Overall QCD Vendor (2015)"
			/>

			<Column className="Column"
				img={ data["Produa_die_skill_contest"] }
				title="Perodua Die Skill Contest（2018）"
			/>

		</Container>
		<ContactIntro />
	</PageCont>
)



export const query= graphql`
	query qualityImgs_en{

		Quality_hero_img: file( relativePath: { eq: "quality/hero-img_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iso14001: file( relativePath: { eq: "quality/iso14001.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iso14001_2015: file( relativePath: { eq: "quality/iso14001-2015.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iso9001: file( relativePath: { eq: "quality/iso9001.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iatf16146: file( relativePath: { eq: "quality/iatf16146.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Daihatsu_regional_contribution_award: file( relativePath: { eq: "quality/daihatsu-regional-contribution-award.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Perodua_best_overall: file( relativePath: { eq: "quality/perodua-best-overall.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Produa_die_skill_contest: file( relativePath: { eq: "quality/produa-die-skill-contest.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`;

export default QualityPage
