import React from 'react';
import { Cont, Img, Title, Cap, Content, Data } from './styles';

const Column = props => (
	<Cont>
		<Img
			fluid={ props.img.childImageSharp.fluid }
			fade={ true }
			withBorder={ props.isIso }
		/>
		<Content>
			<Title>{ props.title }</Title>
			<Cap>{ props.cap }</Cap>
			{ props.data && props.data.map(( info,index ) => (
				<Data>
					<dt key={ index }>{ info.key }</dt>
					<dd key={ index }>{ info.val }</dd>
				</Data>
				))}
		</Content>
	</Cont>
)

export default Column;
