import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Layout from '@components/common/layout';
import { color, mixin, breakpoint,size } from '@src/settings';

export const Title = styled.h3`
	background-color: ${color.blue};
	color: white;
	font-size: 2rem;
	line-height: 6.3rem;
	padding-left: 2.8rem;
`

export const Container = styled.div`
	margin-top: 6rem;
	margin-bottom:8rem;
	${ mixin.baseWidthSet }
	${ media.lessThan(`${ breakpoint.sp}px`)`
		margin-bottom: ${ size.sectionGap_sp }px;
	`}
`
export const PageCont = styled(Layout)`
	${ Container } + ${ Container }{
		margin-top: -2rem;
	}
`;
